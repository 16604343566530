body {
  margin: 0;
  font-family: 'Raleway', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.html{
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.content {
  background-color: #f5f5f5;
  max-width:900px;
  margin: 50px;
  border: 1px solid #3A1F11;
  margin-left:auto;
  margin-right:auto;
  box-shadow: 5px 5px 10px rgba(0,0,0, 0.3);
  line-height: 1.6;
  letter-spacing: 1.5px;
  font-weight: 400;
}
.content h4{
  text-align: center;
  font-size: 25px;
  font-weight: 400;
  margin:20px auto; 
  padding-top: 10px;
  height: fit-content;
}
h5{
  display: block;
  padding: 0px;
  height: fit-content;
  text-align: left;
  margin: 0px;
}
.content-card{
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 65px;
}
.content-text {
  width: 55%;
  padding: 10px ;
  margin: auto;
}
.content-pic{
  width: 350px;
  border-radius: 5px;
  margin: 15px;
}
.mug-pic {
  height: 275px;
}
.bagel-pic {
  width: 350px;
  border-radius: 5px;
  margin: 15px;
}
.visit-title{
  margin: 20px auto;
  width: 50%;
}
.visit-content {
  background-color: #f5f5f5;
  max-width:900px;
  margin: 50px;
  padding:0;
  border: 1px solid #3A1F11;
  margin-left:auto;
  margin-right:auto;
  box-shadow: 5px 5px 10px rgba(0,0,0, 0.3);
  line-height: 1.6;
  letter-spacing: 1.5px;
  font-weight: 400;
}
.visit-content h4{
  position: absolute;
  left: 0;
  right: 0;
  align-items: center;
  text-align: center;
  top: 135px;
  font-size: 20px;
  font-weight: 400;
  margin:0px auto;
  color:#f5f5f5;
  background-color: #3A1F11;
  border-radius: 3px;
  padding:0px 10px;
  width:25%;
  box-shadow: 0 2px 2px 2px rgba(250, 250, 250, 0.25);
}
.visit-content p {
  display: block;
  padding: 0px;
  max-width: 50%;
  margin:20px auto;  
  margin-bottom: 50px;
}
.shop-pic {
  width: 100%;
  height: 450px;
  object-fit:cover;
  padding:0px;
  margin: 0px;
}
.story-pic {
  width: 100%;
  height: 450px;
  object-fit: cover;
  margin-bottom: 25px;
}
.menu-link-container {
  background-color: #3A1F11;
  text-align: center;
  height: 35px;
  padding-top: 10px;
  transition-duration: 0.3s;
}
.menu-link-container:hover {
  margin: 0px auto;
  width: fit-content;
  border: 2px solid #f5f5f5;
  color:#3A1F11;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 5px;
  opacity: 0.6;
  transition: 0.3s;
}
.menu-link {
  text-decoration: none;
  color: #f5f5f5;
}
.menu-link a:link, a:visited,  a:hover, a:active{
  text-decoration: none;
  color: #f5f5f5;
}
.visit-days p{
  padding:0px;
  margin:0px auto;
}
@media screen and (max-width: 1000px) {
  .visit-days p{
    font-weight: 400;
  }
  .content-pic{
    width: 40%;
  }
  .content-text{
    width: 45%;
  }
}
@media screen and (max-width: 500px) {
  .content-text{
    margin: 0 auto;
    padding: 0px 20px;
    text-align: left;
    width: auto;
    margin-bottom: 50px;
  }
  .content-pic{
    width: 100%;
    border-radius: 0px;
    margin: 0px auto;
  }
  .mug-pic {
    margin: 0px auto;
  }
  .visit-content p {
    padding: 0px 20px;
    max-width: 100%;
  }
  .visit-content h4{
    width:fit-content;
    font-size: 20px;
  }
  .visit-title{
    margin: 20px;
    max-width: 100%;
  }
  .story-pic {
    height: 300px;
  }
  .shop-pic {
    height: 300px;
  }
  h5{
    display: block;
    text-align: center;
    padding: 0px;
    margin:10px;
  }
}