.carousel {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    object-fit: cover;
    width: 100%;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
}

.slide {
    box-shadow: 0px 0px 7px #666;
    width: 100%;
    object-fit: cover;
}

.slide-hidden {
    display: none;
}

.arrow {
    position: absolute;
    filter: drop-shadow(0px 0px 3px white);
    width: 2rem;
    height: 2rem;
    color: rgb(58, 31, 17, .5);
}

.arrow:hover {
    cursor: pointer;
    filter: drop-shadow(0px 0px 5px rgb(58, 31, 17));
    color: rgba(225, 225, 225, 0.5);
}

.arrow-left {
    left: 1rem;
}

.arrow-right {
    right: 1rem;
}

.indicators {
    display: flex;
    position: absolute;
    bottom: 1rem;
}

.indicator {
    background-color: white;
    height: 1rem;
    width: 1rem;
    border-radius: 100%;
    border: 1px solid rgb(58, 31, 17, .5);
    outline: none;
    margin: 0 0.5rem;
    cursor: pointer;
}

.indicator-inactive {
    background-color: rgb(58, 31, 17, .5);
    filter: drop-shadow(0px 0px 3px white);
}

@media screen and (max-width: 1200px) {
    .carousel {
        width: auto;
    }
    .indicator {
        display: none;
    }
}